// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import RichText from '~plugins/prismic/components/RichText';
import NodeLink from '~plugins/prismic/components/NodeLink';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import BodySection from '~plugins/material-ui/components/BodySection';
import StyledCard from '~components/StyledCard';
import useArchiveFilters from '~hooks/useArchiveFilters';
import type { PrismicUseCaseArchive } from '~schema';

export type ClassKey =
  | 'root'
  | 'wrapper'
  | 'textWrapper'
  | 'title'
  | 'subtitle'
  | 'itemGridContainer'
  | 'itemGridItem'
  | 'useCase'
  | 'useCaseWrapper';

export type Props = {
  // $FlowFixMe --> Reason: cannot get `node?.data` because property `data` is missing in  `PrismicDocument`
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicUseCaseArchive>>,
};

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
  },
  wrapper: {},
  textWrapper: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
    },
  },
  title: {
    color: theme.palette.common.black,
  },
  subtitle: {
    '* + &': {
      marginTop: theme.spacing(3),
    },
  },
  itemGridContainer: {
    '* + &': {
      marginTop: theme.spacing(7),
    },
  },
  itemGridItem: {},
  useCase: {
    width: '100%',
    height: '100%',
    '&:hover': {
      boxShadow:
        '0px 3px 4px -1px rgba(0,0,0,0.1), 0px 6px 8px 0px rgba(0,0,0,0.1), 0px 1px 14px 0px rgba(0,0,0,0.1)',
    },
  },
  useCaseWrapper: {
    height: '100%',
  },
});

const UseCaseArchivePage = ({ classes, data, node }: Props) => {
  const sortedUseCases = sortBy(data?.allPrismicUseCase?.nodes, [
    useCase => useCase?.data?.publication_date || useCase?.first_publication_date,
  ]).reverse();

  const [filter, filteredUseCases] = useArchiveFilters(sortedUseCases);

  return (
    <div className={classes.root}>
      <BodySection className={classes.wrapper}>
        <div className={classes.textWrapper}>
          <RichText {...node?.data?.title} className={classes.title} />
          <RichText
            {...node?.data?.name?.subtitle}
            className={classes.subtitle}
          />
        </div>
        {filter}
        <Grid
          container
          spacing={4}
          alignItems="stretch"
          className={classes.itemGridContainer}
        >
          {map(filteredUseCases, (useCase, index: number) => {
            return (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                className={classes.itemGridItem}
              >
                <StyledCard
                  node={useCase}
                  title={useCase?.data?.title}
                  image={useCase?.data?.background_image}
                  useCaseLogo={useCase?.data?.use_case_logo}
                  cardActionAreaProps={{
                    component: NodeLink,
                    node: useCase,
                  }}
                  className={classes.useCase}
                  classes={{
                    wrapper: classes.useCaseWrapper,
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </BodySection>
    </div>
  );
};

export default compose(
  withNodePage<PrismicUseCaseArchive, *>({
    getNode: data => data?.prismicUseCaseArchive,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'UseCaseArchivePage' }),
)(UseCaseArchivePage);

export const query = graphql`
  query UseCaseArchiveQuery($prismicId: ID, $prismicLocaleId: String) {
    prismicUseCaseArchive(prismicId: { eq: $prismicId }) {
      id
      lang
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        title {
          text
          html
        }
        subtitle {
          text
          html
        }
      }
    }
    allPrismicUseCase(filter: { lang: { eq: $prismicLocaleId } }) {
      nodes {
        id
        lang
        type
        href
        first_publication_date
        uid
        data {
          title {
            text
            html
          }
          publication_date
          background_image {
            alt
            fluid(maxWidth: 300) {
              ...GatsbyPrismicImageFluid
            }
          }
          tags {
            tag {
              id
              uid
              document {
                ... on PrismicEntryTag {
                  id
                  uid
                  data {
                    name {
                      text
                      html
                    }
                  }
                }
              }
            }
          }
          use_case_logo {
            alt
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 80, quality: 85) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          body {
            ... on Node {
              id
            }
            ... on PrismicUseCaseBodyText {
              slice_type
              primary {
                slice_text {
                  text
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`;
